import { InputProps, TextFieldProps } from "@material-ui/core"

import {
  ISettingContainerBaseProps,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import { SettingText } from "src/components/Settings/Setting/SettingText"
import { spacing } from "src/ui/spacing"

export function ParadiseSettingText({
  title,
  value,
  onSave,
  ...props
}: ISettingContainerBaseProps & {
  textFieldProps?: TextFieldProps
  inputProps?: InputProps["inputProps"]
  onSave: (newVal: string) => TSettingContainerOnSaveReturnType
  value: string
}) {
  return (
    <SettingText
      title={title}
      titleProps={{
        type: "nano",
        color: "secondary",
      }}
      displayValueProps={{
        type: "bodyS",
        color: "primary",
      }}
      value={value}
      gap={spacing.XS2}
      onSave={onSave}
      {...props}
    />
  )
}
